<template>
<div>
<div class="card p-2 bg-card">
   <div class="row">
      <div class="col-12 mb-1">
        <div class="d-flex justify-content-between align-items-center">
         <input
          v-model="searchCustomers"
          placeholder="Search"
          class="mr-bottom form-control input-sm mr-1 ml-1 mt-1"
          style="max-width: 200px"
        />
        <button
          class="btn btn-outline-primary btn-sm float-right mr-1 mt-1"
          @click="openAddUser()"
          :disabled="!this.$store.state.auth.permissions.includes('customer-create')"
        >
         + Add 
        </button>
        </div>
      </div>
    </div>
</div>
  <div class="card p-2">
   
    
    <table class="table border mb-1">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Company</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
       
        <tr v-for="(company, index) in companies" :key="company._id">
          <td scope="row">{{ (currentPage - 1) * perPage + index + 1 }}</td>
          <td>{{ company.name }}</td>
          <td>
            <button
              class="btn btn-primary btn-sm mr-1"
              @click="viewDetails(company._id)"
            >
              View Details
            </button>
            
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col-12 float-right">
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          hide-goto-end-buttons
          :total-rows="lastPage * perPage"
          class="float-right"
        />
      </div>
    </div>

    <b-modal ref="modal-companydetails" title="Company Details" hide-footer>
      <h5 class="mb-1">
        <b>{{ companydetails.name }}</b>
      </h5>
      <div
        class="d-flex justify-content-between"
        v-for="user in companyusers"
        :key="user._id"
      >
        <p>{{ user.firstname }} {{ "" }} {{ user.lastname }}</p>
        <p v-for="role in user.roles" :key="role._id">{{ role.name }}</p>
        <span
          @click="openremoveuser(user.user_id)"
          class="icon-box-new"
          v-if="user.type == 'GRC_user'"
          
        >
          <feather-icon
            class="text-danger pointer rotate"
            icon="Trash2Icon"
            size="20"
          />
        </span>
      </div>

      <!-- {{companydetails.name}} -->
      <!-- {{label_options}} -->
    </b-modal>
    <b-modal ref="modal-adduser" title="Assign VM User " hide-footer>
      <!-- {{assignusers}} -->
      <!-- <v-select
        v-model="selecteduser"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="title"
        :options="optionuser"
        
      /> -->
      <label for="">User</label>
      <select name="" id="" v-model="selecteduser" class="form-control">
        <option
          :value="user.user_id"
          v-for="user in this.assignusers"
          :key="user._id"
        >
          {{ user.firstname }} {{ user.lastname }}
        </option>
      </select>

      <b-form-group label="Role" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          id="checkbox-group-1"
          v-model="roles"
          :options="options"
          :aria-describedby="ariaDescribedby"
          name="flavour-1"
        ></b-form-checkbox-group>
      </b-form-group>
      <!-- {{ selecteduser }} -->

      <button class="btn btn-primary btn-sm mt-1" @click="assignuser()">
        Assign User
      </button>
    </b-modal>
    <b-sidebar
      id="add-user"
      bg-variant="white"
      right
      backdrop
      shadow
      width="640px"
      v-model="add_user"
    >
      <div class="p-2">
        <validation-observer ref="observer">
          <b-form-group label="Company Name" label-for="basicInput">
            <validation-provider
              #default="{ errors }"
              name="Company"
              rules="required"
            >
              <b-form-input
                id="basicInput"
                placeholder="Company Name"
                v-model="companyname"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="First Name" label-for="basicInput">
            <validation-provider
              #default="{ errors }"
              name="Firstname"
              rules="required"
            >
              <b-form-input
                id="basicInput"
                placeholder="First Name"
                v-model="fname"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Last Name" label-for="basicInput">
            <validation-provider
              #default="{ errors }"
              name="Lastname"
              rules="required"
            >
              <b-form-input
                id="basicInput"
                placeholder="Last Name"
                v-model="lname"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Email" label-for="basicInput">
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                id="basicInput"
                placeholder="Email"
                v-model="emailid"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Phone Number" label-for="basicInput">
            <validation-provider
              #default="{ errors }"
              name="Phonenumber"
              rules="required"
            >
              <b-form-input
                id="basicInput"
                placeholder="Phone Number"
                v-model="phonenumber"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox v-model="should_invite">
              Send invitation to mail
            </b-form-checkbox>
          </b-form-group>

          <button
            class="btn btn-primary mt-1 mr-1"
            @click.prevent="validationForm"
          >
            Add
          </button>
          <button class="btn btn-secondary mt-1" @click="closeAdduser()">
            Cancel
          </button>
        </validation-observer>
      </div>
    </b-sidebar>
  </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BFormGroup,
  BFormCheckboxGroup,
  BCardText,
  BFormCheckbox,
  BPagination,
  BSidebar,
  BFormInput,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
  data() {
    return {
      companies: [],
      companydetails: [],
      tenantid: "",
      companyusers: [],
      assignusers: [],
      optionuser: [],
      selecteduser: "",
      roles: [],
      options: [
        { text: "Admin", value: "admin" },
        { text: "User", value: "user" },
        { text: "CISO", value: "ciso" },
        { text: "VM Consultant", value: "vm_consultant" },
      ],
      currentPage: 1,
      lastPage: 1,
      perPage: 10,
      required,
      email,
      fname: "",
      lname: "",
      companyname: "",
      emailid: "",
      phonenumber: "",
      should_invite: "",
      add_user: false,
      searchCustomers:'',
    };
  },
  components: {
    vSelect,
    BFormGroup,
    BFormCheckboxGroup,
    BCardText,
    BFormCheckbox,
    BPagination,
    BSidebar,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  watch: {
    currentPage(value) {
      this.getCompanies(value);
    },
    searchCustomers(){
      this.getCompanies(this.currentPage);
    }
  },
  mounted() {
    this.getCompanies(this.currentPage);
    this.getUsers();
  },
  methods: {
    openAddUser() {
      this.add_user = true;
    },
 async   closeAdduser() {
      this.add_user = false;
       this.fname ="";
      this.lname = "";
      this.companyname = "";
      this.emailid = "";
      this.phonenumber = "";
      this.should_invite = false
      this.$nextTick(() => this.$refs.observer.reset());
    },
    validationForm() {
      this.$refs.observer.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.AddUser();
        }
      });
    },
    AddUser() {
      const data = {
        company: this.companyname,
        firstname: this.fname,
        lastname: this.lname,
        email: this.emailid,
        mobile: this.phonenumber,
        should_invite: this.should_invite,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_CISOGRC_API_URL + `/customer/sign-up`,
      };
      this.$http(options)
        .then((res) => {
          this.closeAdduser();
          this.getCompanies(this.currentPage);
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openremoveuser(uid) {
      const data = {
        tenant_id: this.tenantid,
        user_id: uid,
        // roles: this.roles,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/customers/${this.tenantid}/remove_vm_user`,
      };
      this.$http(options)
        .then((res) => {
          this.$refs["modal-companydetails"].hide();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    assignuser() {
      const data = {
        tenant_id: this.tenantid,
        user_id: this.selecteduser,
        roles: this.roles,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/customers/${this.tenantid}/assign_vm_user`,
      };
      this.$http(options)
        .then((res) => {
          this.$refs["modal-adduser"].hide();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUsers() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/user/search`,
      };
      this.$http(options)
        .then((res) => {
          this.assignusers = res.data.data;
          for (let i in this.assignusers) {
            let user = {
              title: value,
            };
            this.optionuser.push(user);
          }

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    assignUser(id) {
      this.viewDetailsCompany(id);
      this.$refs["modal-adduser"].show();
    },
    getCompanies(pageno) {
      const options = {
        method: "GET",
        params: { page: pageno, limit: this.perPage },
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/companies?name=${this.searchCustomers}`,
      };
      this.$http(options)
        .then((res) => {
          this.companies = res.data.data.data;
          this.currentPage = res.data.data.current_page;
          this.lastPage = res.data.data.last_page;
          this.perPage = res.data.data.per_page;

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    viewDetails(id) {
      this.$router.push({ name: "customermember", params: { id: id } });
      // const options = {
      //   method: "GET",
      //   headers: { "content-type": "application/json" },
      //   url: process.env.VUE_APP_ONECSF_API_URL + `/companies/${id}`,
      // };
      // this.$http(options)
      //   .then((res) => {
      //     this.companydetails = res.data.data.company;
      //     this.tenantid = res.data.data.company.tenant_uid;
      //     this.viewCompanyUsers(this.tenantid);

      //     this.$refs["modal-companydetails"].show();

      //     console.log(res.data);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    viewDetailsCompany(id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/companies/${id}`,
      };
      this.$http(options)
        .then((res) => {
          this.companydetails = res.data.data.company;
          this.tenantid = res.data.data.company.tenant_uid;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    viewCompanyUsers(id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/customers/${id}`,
      };
      this.$http(options)
        .then((res) => {
          this.companyusers = res.data.data.data.data;

          this.$refs["modal-companydetails"].show();

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
th{
  background: #472183 !important;
  color: #fff;
}
</style>
